import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { Button } from "../components/Button"
import ButtonB from "../components/ButtonB"
import TextAccordion from "../components/TextAccordion/TextAccordion"
import "../styles/visibility-and-insights.scss"
import ScrollAnimation from "react-animate-on-scroll"
import ContactForm from "../components/ContactForm"
import LogoCarousel from "../components/LogoCarousel"

//Images
import Divider from "../images/Blue-line.png"
import { BiSearchAlt2, BiWindow, BiWindowAlt } from "react-icons/bi"
import { RiCompassDiscoverLine } from "react-icons/ri"
import { IoIosAnalytics } from "react-icons/io"
import { CgInsights } from "react-icons/cg"
import { FaCircle, FaRegCircle } from "react-icons/fa"
import hcIcon1 from "../images/icon1.png"
import hcIcon2 from "../images/icon2.png"
import hcIcon3 from "../images/icon3.png"

import MapImg from "../images/map.png"

//data for accordion

//Hyper-Converged
const panels1 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Develop a unified, software-defined infrastructure system, pooling the core components of a traditional data centre. Metsi’s expertise in delivering HCI platforms streamlines the process of making software and hardware upgrades, making it simpler and quicker than managing traditional infrastructure systems.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Customers benefit from smaller upfront investments as HCI generally has a smaller hardware footprint. HCI’s bite-sized scaling costs are another significant benefit to customers who may be accustomed to 'forklift' upgrades.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "In almost all use-cases HCI is now the de-facto choice for on-premises and hybrid virtualisation platforms over legacy 3-tier architecture. HCI provides better ROI and smoother scaling due to the virtualisation of storage alongside the compute.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Hybrid Multi-Cloud Platform
const panels2 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Metsi is able to deliver your choice of Hybrid Multi-Cloud Platform enabling your organisation to fulfil your cloud requirements by extending on-premises environments seamlessly into the public cloud, unlocking greater business agility and cost efficiency.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Customers can use hybrid multi-cloud to enhance their performance/cost ratio where workloads either massively scale or are not easily forecastable. Hybrid cloud management solutions optimise the location of workloads by ensuring they are in the lowest cost and best performance location at a given time and customers can benefit from a resilient multi-vendor architecture.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Hybrid Multi-Cloud Platform Infrastructure avoids vendor lock-in and is ideally suited for variable-scale workloads. Baseline workload levels can be maintained on cheaper on-prem private clouds, while the flexibility of public clouds can be leveraged for bursting and rapid fluctuations in scale such as seasonal or erratic workloads. Reservations can be made for planned increases in scale over a set time frame or, if unplanned, reacted to for erratic or irregular workloads (such as fail-over events or surges in customer activity).",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

//Unified Management and Control
const panels3 = [
  {
    icon: "",
    title: "Summary",
    content:
      "Enable intelligence-driven decision-making by securely managing and sharing business data. Our unified management and control capanbilities allow you to eliminate the storage silos that limit visibility, block access, and inhibit innovation.",
    image:
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg",
  },
  {
    title: "Value to our customers",
    content:
      "Customers benefit from solutions that are highly adaptable and customisable, leveraging automation and orchestration of multi-vendor technologies to simplify management and reduce cost.",
    image:
      "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
  },
  {
    title: "Use cases",
    content:
      "Unified Management and Control ensures the smallest management footprint possible by adopting a single pane of glass to manage multiple systems. They provide easier, quicker and less costly training due to the smaller vendor and system footprint for staff to learn.",
    image:
      "https://cdn.jpegmini.com/user/images/slider_puffin_before_mobile.jpg",
  },
]

const InfrastructurePage = () => {
  //gatsby-image query
  const data = useStaticQuery(graphql`
    query InfrastructureImages {
      hero1: file(relativePath: { eq: "desert_thunder.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      woods1: file(relativePath: { eq: "forest_sunshine.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      forest1: file(relativePath: { eq: "jungle_river.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Infrastructure"
        description="Metegration; optimising native cloud computing is used to reap the benefits of workflow automation."
        keywords={[
          "application management services",
          "application lifecycle management consulting",
          "devops application management",
          "application modernization services",
          "application modernisation ",
          "legacy application modernisation services",
          "legacy application modernisation",
        ]}
        lang="en-gb"
      />

      {/********* Hero Section ***********/}
      <BackgroundImage
        fluid={data.hero1.childImageSharp.fluid}
        className="visibility-hero"
        style={{
          width: "100vw",

          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="hero-content">
          <div className="transparent">
            <div className="outer-container">
              <div className="row">
                <div className="inner-container-2 left">
                  <div className="droplet-blue">
                    <BiWindowAlt className="icon" />
                  </div>
                </div>
                <div className="inner-container-2 right">
                  <div className="column">
                    <h1>Infrastructure</h1>
                    <img src={Divider} alt="divider" className="divider" />
                    <p style={{ color: "#626366" }}>
                      Metsi’s engineering teams are experienced in all aspects
                      of infrastructure management and migration. We take a
                      “bigger picture” approach, guiding not only infrastructure
                      platform strategy, but also execution on delivery ensuring
                      business applications are seamlessly moved onto your new
                      platform. Metsi is able to deliver Hybrid Multi-Cloud
                      Solutions, Unified Management and Control, and
                      Hyper-Converged Infrastructure. With millions of workloads
                      successfully migrated across a breadth of organisations
                      and industries we are able to overcome any challenges that
                      may arise and deliver you the results you deserve.
                    </p>
                    {/* <ButtonB href="#services">Learn More</ButtonB> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      {/********* Services ***********/}
      <div className="section services" id="services">
        <div className="inner-container">
          <h2>Infrastructure</h2>
          {/* <h3>Innovate with Full Stack Observability</h3>
          <p>
            Full-stack observability – or observability without limits - enables
            IT teams to monitor an entire IT stack, from customer-facing
            applications to core network and infrastructure, and determine which
            resources must take priority.
          </p>
          <p>
            Metsi expedites digital innovation initiatives by leveraging APIs
            for IT automation, orchestration and integration; optimising cloud
            native computing to reap the benefits of extensive workflow
            automation.
          </p> */}
        </div>
        <div className="row" style={{ maxWidth: "1400px" }}>
          <div className="inner-container-3" style={{ width: "100%" }}>
            <a href="#hyper-converged">
              <div className="droplet-blue">
                {/* <RiCompassDiscoverLine className="icon" /> */}
                <img
                  src={hcIcon1}
                  className="icon"
                  style={{ transform: "scale(0.7)" }}
                />
              </div>
            </a>
            <a href="#hyper-converged">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Hyper-Converged
              </h2>
            </a>
          </div>

          <div className="inner-container-3" style={{ width: "100%" }}>
            <a href="#hybrid-multi-cloud-platform">
              <div className="droplet-blue">
                {/* <IoIosAnalytics className="icon" /> */}
                <img
                  src={hcIcon2}
                  className="icon"
                  style={{ transform: "scale(0.7)" }}
                />
              </div>
            </a>
            <a href="#hybrid-multi-cloud-platform">
              <h2 className="droplet-header" style={{ color: "#000" }}>
                Hybrid Multi-Cloud Platform
              </h2>
            </a>
          </div>

          <div className="inner-container-3" style={{ width: "100%" }}>
            <a href="#unified-management-and-control">
              <div className="droplet-blue">
                {/* <CgInsights className="icon" /> */}
                <img
                  src={hcIcon3}
                  className="icon"
                  style={{ transform: "scale(0.7)" }}
                />
              </div>
            </a>
            <a href="unified-management-and-control" style={{ color: "#000" }}>
              <h2 className="droplet-header">Unified Management and Control</h2>
            </a>
          </div>
        </div>
      </div>
      {/********* Hyper-converged ***********/}
      <BackgroundImage
        fluid={data.woods1.childImageSharp.fluid}
        className="section"
        id="hyper-converged"
        style={{
          width: "100vw",
          minHeight: "60vh",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Infrastructure</h2>
              <h3>Hyper-Converged</h3>
            </div>
          </div>
          {/* <h1>{panels1[0].content}</h1>    */}
          {/* <div className="content-container"> */}
          {/* <div className="column"> */}
          {/* <h4>Summary</h4> */}
          {/* <p>{panels1[0].content}</p> */}
          {/* </div> */}
          {/* </div> */}
          {<TextAccordion panels={panels1} />}
        </div>
      </BackgroundImage>

      {/********* Hybrid Multi-Cloud Platform ***********/}
      <div className="section" id="hybrid-multi-cloud-platform">
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Infrastructure</h2>
              <h3>Hybrid Multi-Cloud Platform</h3>
            </div>
          </div>
          {/* <div className="content-container"> */}
          {/* <div className="column"> */}
          {/* <h4>Summary</h4> */}
          {/* <p>{panels2[0].content}</p> */}
          {/* </div> */}
          {/* </div> */}
          {<TextAccordion panels={panels2} />}
        </div>
      </div>

      {/******** Unified Management and Control ********/}
      <BackgroundImage
        fluid={data.forest1.childImageSharp.fluid}
        className="section"
        id="unified-management-and-control"
        style={{
          width: "100vw",
          minHeight: "60vh",
          backgroundPosition: "bottom",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
        }}
      >
        <div className="overlay section">
          <div className="outer-container">
            <div className="inner-container">
              <h2>Infrastructure</h2>
              <h3>Unified Management and Control</h3>
            </div>
          </div>
          {/* <div className="content-container"> */}
          {/* <div className="column"> */}
          {/* <h4>Summary</h4> */}
          {/* <p>{panels3[0].content}</p> */}
          {/* </div> */}
          {/* </div> */}
          {<TextAccordion panels={panels3} />}
        </div>
      </BackgroundImage>

      {/*********** Partners *************/}
      <div className="section partners">
        <div className="outer-container" style={{ display: "block" }}>
          <div
            className="droplet-blue-invert"
            style={{ marginTop: "25px", float: "left" }}
          >
            Our Partners
          </div>
          <div style={{ display: "block" }}>
            <LogoCarousel />
          </div>
        </div>
      </div>
      {/*********** Contact *************/}
      <div className="contact-section" id="contact">
        <div className="outer-container">
          <div className="contact-header">
            <h2>Contact Us</h2>
            <ScrollAnimation animateIn="animate__fadeInUp" duration="1.3">
              <h1 style={{ textAlign: "center" }}>
                Have a question about our solutions?
              </h1>
            </ScrollAnimation>
          </div>
          <div className="row">
            <div className="inner-container-2">
              <ContactForm />
            </div>
            <div className="inner-container-2">
              <div className="row">
                <div className="inner-container">
                  <img src={MapImg} alt="map" style={{ width: "100%" }} />
                </div>
              </div>
              <div className="row">
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Office Locations</h4>
                  <ul>
                    <li>
                      <FaCircle className="icon" id="uk" />
                      UK
                    </li>
                    <li>
                      <FaCircle className="icon" id="usa" />
                      USA
                    </li>
                    <li>
                      <FaCircle className="icon" id="germany" />
                      Germany
                    </li>
                    <li>
                      <FaCircle className="icon" id="netherlands" />
                      The Netherlands
                    </li>
                    <li>
                      <FaCircle
                        className="icon"
                        id="india"
                        style={{ color: "transparent" }}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="inner-sub-container-2"
                  style={{
                    alignItems: "flex-start",
                    textAlign: "left",
                    padding: "40px 50px",
                  }}
                >
                  <h4 style={{ color: "#21B7E0" }}>Satellite Locations</h4>
                  <ul>
                    <li>
                      <FaRegCircle className="icon" id="belgium" />
                      Belgium
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="sa" />
                      South Africa
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="poland" />
                      Poland
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="spain" />
                      Spain
                    </li>
                    <li>
                      <FaRegCircle className="icon" id="india" />
                      India
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InfrastructurePage
